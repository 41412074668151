<template>
  <v-card class="home-card card d-flex flex-column secondary--text" :style="{ 'background': $vuetify.theme.themes.light.background }" :to="{ name: 'lang.index' }">
    <v-img class="slider-img" :src="slider" />
    <div class="card-footer d-flex align-center justify-space-between px-15">
      <div>
        <v-img v-if="logo" width="193" :src="logo" />
        <v-img v-else width="193" src="@/assets/home/icon.svg"></v-img>
      </div>
      <div>
        <h1 class="card-title">Tap to start</h1>
        <h1 class="card-title" dir="rtl">ءﺪﺒﻠﻟ ﺮﻘﻧا</h1>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_CMS_SLIDERS } from '../../store/app/types/action';

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      sliders: 'app/sliders',
      getConcept: 'concept/getConcept',
      getApplications: 'concept/getApplications',
    }),
    logo () {
      return this.getConcept && this.getConcept['logo-uri'];
    },
    slider () {
      return this.sliders.included
        && Array.isArray(this.sliders.included)
        && this.sliders.included.length
        && this.sliders.included[0].attributes
        && this.sliders.included[0].attributes['image-uri'];
    }
  },
  methods: {
    ...mapActions({
      FETCH_CMS_SLIDERS: `app/${FETCH_CMS_SLIDERS}`,
    }),
  },
  mounted() {
    this.FETCH_CMS_SLIDERS();
  },
};
</script>
<style lang="scss" scoped>
.slider-img {
  height: calc(100vh - 293px)
}
.card{

  &-title{
    font-weight: bold;
    font-size: 72px;
    line-height: 84px;

  }
  &-footer{
    width: 100%;
    height: 293px;
  }
}
</style>
